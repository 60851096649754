// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Role from "../../../../models/Role.res.js";
import * as State from "../../../../models/State.res.js";
import * as Country from "../../../../models/Country.res.js";
import * as $$Location from "../../../../models/Location.res.js";
import * as Provider from "../../../../models/Provider.res.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as ProviderLocalityShow from "../../common/provider-locality-show/ProviderLocalityShow.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              provider: field.required("provider", Provider.Locality.Show.decoder),
              country: field.required("country", Country.decoder),
              locations: field.required("locations", Json_Decode$JsonCombinators.array($$Location.ForTile.decoder)),
              mapLocations: field.required("mapLocations", Json_Decode$JsonCombinators.array($$Location.ForMap.decoder)),
              providersIndex: field.required("providersIndex", Provider.List.decoder),
              statesIndex: field.required("statesIndex", State.List.decoder),
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              totalProviders: field.required("totalProviders", Json_Decode$JsonCombinators.$$int),
              totalLocations: field.required("totalLocations", Json_Decode$JsonCombinators.$$int),
              totalStates: field.required("totalStates", Json_Decode$JsonCombinators.$$int),
              offset: field.required("offset", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

function ProviderCountryShow$default(props) {
  var show = props.show;
  return JsxRuntime.jsx(ProviderLocalityShow.make, {
              provider: show.provider,
              country: show.country,
              index: {
                locations: show.locations,
                mapLocations: show.mapLocations,
                providers: show.providersIndex.providers,
                currentPage: show.currentPage,
                totalPages: show.totalPages,
                totalLocations: show.totalLocations,
                offset: show.offset
              },
              totalStates: show.totalStates,
              totalProviders: show.totalProviders,
              statesIndex: show.statesIndex,
              totalLocations: show.totalLocations,
              userRole: Role.fromString(props.userRole),
              content: ""
            });
}

var $$default = ProviderCountryShow$default;

export {
  decoder ,
  fromJson ,
  $$default as default,
}
/* decoder Not a pure module */
